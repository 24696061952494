import React , {useEffect} from "react";
// import footerlogo from "../../"
import footerlogo from "../../Assets/Images/Aby-footer-logo.svg";
import AOS from "aos";
import 'aos/dist/aos.css';
import './footer.css'

const Footer = () => {
    useEffect(()=>{
        AOS.init({duration: 2000});
      },[])
  return (
    <section class="footer text-center py-5" style={{marginTop:"40px"}}>
        <div class="head-logo part" data-aos="fade-down">
            <img src={footerlogo} alt="" />
            
        </div>
        <div class="links-footer part text-center" data-aos="fade-down">
            <div class="links-socialmedia text-center">
                <ul class="icons">
                    <li class="nav-item">
                        <a href="https://www.linkedin.com/in/abiyya-racing-153571265/">
                        <i className="icon fa-brands fa-linkedin mx-2"></i>     
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="https://www.instagram.com/abiyyaracing/?igshid=OGQ5ZDc2ODk2ZA">
                        <i className="icon fa-brands fa-instagram mx-2"></i>     
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="https://www.tiktok.com/@abiyyaracing?_t=8ebtswWHV9c&_r=1">
                        <i className="icon fa-brands fa-tiktok mx-2"></i>      
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="https://twitter.com/Abiyya_Racing?t=GN8bvoFJcXIpniU_fFQ_cA&s=09">
                        <i className="icon fa-brands fa-twitter mx-2"></i>    
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="https://open.spotify.com/user/317jkrx7ztj6ziklnd7pke7odvh4?si=3H2Gq8wlQtagcB8hqlAo4A">
                        <i className="icon fa-brands fa-spotify mx-2"></i>    
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="text-footer part" data-aos="fade-down">
            <a href="/#">Follow us</a>
        </div>
    </section>
  )
}

export default Footer
