import React, { useEffect, useState } from "react";

import Scrollspy from "react-scrollspy";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
// hero
// about
import s1 from "../../Assets/Images/mang-icon.svg";
import s2 from "../../Assets/Images/desgin-icon.svg";
import s3 from "../../Assets/Images/enginner-icon.svg";

// aby---------------------
import Logo from "../../Assets/Images/Aby-bg.svg";

import titleicon from "../../Assets/Images/aby-small-icon.svg";
// who-aby
import hourse from "../../Assets/Images/aby-hourse.svg";

// team
import Slider from "react-slick";

import saba from "../../Assets/Images/saba.png";
import layan from "../../Assets/Images/Ab-layan.svg";
import gana from "../../Assets/Images/ab-gana.svg";
import nada from "../../Assets/Images/Ab-nada.svg";
import ali from "../../Assets/Images/ab-ali.svg";
import imran from "../../Assets/Images/ab-imran.svg";

// sus
import su1 from "../../Assets/Images/ab-su-1.svg";
import su2 from "../../Assets/Images/ab-su-2.svg";
import su3 from "../../Assets/Images/ab-su-3.svg";
import su4 from "../../Assets/Images/ab-su-4.svg";

// import t5 from "../../Assets/Images/t5.svg";
import video from "../../Assets/Images/new_abiyya.mp4";
// ============news
import icon1news from '../../Assets/Images/iconNews1.png';
import icon2news from '../../Assets/Images/iconNews2.png';
import icon3news from '../../Assets/Images/iconNews3.png';


// =================================sponsers
// import smart from "../../Assets/Images/smart-logo.svg";
// import orecal from "../../Assets/Images/Oracle-Logo.svg";
// import ithar from "../../Assets/Images/ithra-logo.svg";
// import inschool from "../../Assets/Images/inschool-logo.svg";
// import Aramco from "../../Assets/Images/Saudi-Aramco-Logo.svg";
// import mesk from "../../Assets/Images/meskScool-logo.svg";

import Abce from "../../Assets/Images/Sponsers/Abce.png";
import aramco from "../../Assets/Images/Sponsers/aramco.png";
import Ayato from "../../Assets/Images/Sponsers/Ayato.png";
import Bell from "../../Assets/Images/Sponsers/Bell.png";
import brometra from "../../Assets/Images/Sponsers/brometra.png";
import cragne from "../../Assets/Images/Sponsers/cragna.png";
import isra from "../../Assets/Images/Sponsers/isra.png";
import nabhal from "../../Assets/Images/Sponsers/manhal.png";
import mask from "../../Assets/Images/Sponsers/mask.png";
import rasail from "../../Assets/Images/Sponsers/rasail.png";
import Reach from "../../Assets/Images/Sponsers/Reach.png";
import sado from "../../Assets/Images/Sponsers/sado.png";
import samnan from "../../Assets/Images/Sponsers/samnan.png";
import shopzy from "../../Assets/Images/Sponsers/Shopzy.png";
import smart from "../../Assets/Images/Sponsers/smart.png";

import F1 from "../../Assets/F1.png";

import AOS from "aos";
import "aos/dist/aos.css";

import "./Navbar.css";

const Navbar = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 769,
        settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false },
      },
      {
        breakpoint: 425,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
      },
    ],
  };
  const settingsicons = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          speed: 2000,
          autoplaySpeed: 1000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          speed: 2000,
          autoplaySpeed: 1000,
          cssEase: "linear",
        },
      },
    ],
  };
  // who-slide
  const settingswho = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [messageValue, seMessageValue] = useState("");

  return (
    <>
      {/* nav-bar ===============================*/}
      <section style={{ marginBottom: "80px" }} className="navbarsection">
        <nav
          id="navbar-example"
          class="navbar navbar-expand-lg navbar-light  fixed-top shadow-sm"
          data-aos="fade-down"
        >
          <div className="container py-3">
            <a class="navbar-brand" href="#hero">
              <img className="logo " src={Logo} alt="logo" />
            </a>
            <button
              class="navbar-toggler"
              style={{ marginLeft: "40%" }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              {/* <ul class="navbar-nav ms-auto mb-2 mb-lg-0"> */}
              <Scrollspy
                className=" ml-auto mb-2 mb-lg-0 scrollbox"
                items={["hero", "about", "team", "sponsers"]}
                currentClassName="active"
              >
                <li className="nav-item px-2">
                  <a className="nav-link" aria-current="page" href="#hero">
                    Home
                  </a>
                </li>
                <li className="nav-item px-2">
                  <a className="nav-link" href="#about">
                    About
                  </a>
                </li>
                <li className="nav-item px-2">
                  <a className="nav-link" href="#team">
                    Our team
                  </a>
                </li>
                <li className="nav-item px-2">
                  <a className="nav-link " href="#sustainability">
                    Sustainability Plan
                  </a>
                </li>
                <li className="nav-item px-2">
                  <a className="nav-link" href="#about">
                    accomplishments
                  </a>
                </li>
                <li className="nav-item px-2">
                  <a className="nav-link" href="#sponsers">
                    Sponsers
                  </a>
                </li>
                <li className="nav-item px-2">
                  <a className="nav-link" href="#faq">
                    Q&A
                  </a>
                </li>
              </Scrollspy>
              {/* </ul> */}

              {/* icons */}

              <ul className="ml-auto mb-2 mb-lg-0  icons d-flex justify-content-end">
                {/* <img src={F1} className="w-25" alt=".."/> */}

                {/* <img src={F1} className="w-25 icon" alt=".."/> */}
                {/* <i className="icon fa-brands fa-linkedin"> */}
                <img src={F1} className="w-50" alt=".." />
                {/* </i> */}
                {/* </a> */}
                {/* <a
                  href="https://www.instagram.com/abiyya.racing/?igshid=ZDdkNTZiNTM%3D"
                  className="me-3"
                >
                  <i className="icon fa-brands fa-instagram"></i>
                </a>
                <a href="https://www.tiktok.com/@abiyyaracing" className="me-3">
                  <i className="icon fa-brands fa-tiktok"></i>
                </a>
                <a
                  href="https://twitter.com/Abiyya_Racing?t=GN8bvoFJcXIpniU_fFQ_cA&s=09"
                  className="me-3"
                >
                  <i className="icon fa-brands fa-twitter"></i>
                </a> */}
              </ul>
            </div>
          </div>
        </nav>
      </section>
      {/* hero =======================================*/}
      <section id="hero" className="herosection position-relative">
        <div className=" content text-center "></div>
        <h2 data-aos="fade-down">Bound to make history</h2>
      </section>
      {/* services-section =============================*/}
      <section
        className="Sevices"
        style={{ paddingTop: "50px", paddingBottom: "100px" }}
      >
        <div className="container content">
          <div className="title-font text-center">
            <span>
              <img
                className="icon"
                src={titleicon}
                alt=""
                style={{ width: "5%" }}
                data-aos="fade-right"
              />
            </span>
            Our Fields
          </div>
          <div className="row">
            
            <div className="col-md-4">
              <div className="data text-center px-5" data-aos="fade-right">
                <div className="box-serv p-5">
                  <div className="front-serv">
                    <img src={s1} alt="" className="logo-1"  />
                  </div>
                  <div className="back-serv p-3">
                    <h2 > involves coordinating team activities, defining roles, setting deadlines, and handling resources. It requires problem-solving and decision-making skills to ensure successful project completion.”</h2>
                  </div>               
                </div>

                <h4 className="my-5 service-h4">Management</h4>
              </div>
            </div>
           
            <div className="col-md-4">
              <div className="data text-center ">

              <div className="data text-center px-5">
                <div className="box-serv p-5">
                  <div className="front-serv">
                    <img src={s2} alt="" className="logo-2"/>
                  </div>
                  <div className="back-serv p-3">
                    <h2 >Teams design and manufacture a miniature F1-style car using CAD/CAM software and technology. This involves understanding aerodynamics, physics, and material science to create the fastest and most efficient car.</h2>
                  </div>               
                </div>

                <h4 className="my-5 service-h4">Engineering &  Design</h4>
              </div>

               
              </div>
            </div>

            
            <div className="col-md-4 ">
              <div className="data text-center " data-aos="fade-left">

                <div className="data text-center px-5">
                <div className="box-serv p-5">
                  <div className="front-serv">
                    <img src={s3} alt="" className="logo-3"/>
                  </div>
                  <div className="back-serv p-3">
                    <h2 >Teams must find and secure partnerships with businesses and individuals to fund their project. This involves creating a business plan, pitching to potential sponsors, and managing relationships with these partners.</h2>
                  </div>               
                </div>

                <h4 className="my-5 service-h4">Enterprise</h4>
              </div>
              </div>
            </div>

          </div>
          {/* tabs */}
          {/* <div className="tabs_serv my-4">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Management
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Engineering & Design
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  Enterprise
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row py-4">
                  <div className="col-md-6 my-3">
                    <div className="serv_dec">
                      <p>
                        involves coordinating team activities, defining roles,
                        setting deadlines, and handling resources. It requires
                        problem-solving and decision-making skills to ensure
                        successful project completion.”
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 my-3">
                    <div className="serv_img text-center">
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="row py-4">
                  <div className="col-md-6 my-3">
                    <div className="serv_dec">
                      <p>
                        Teams design and manufacture a miniature F1-style car
                        using CAD/CAM software and technology. This involves
                        understanding aerodynamics, physics, and material
                        science to create the fastest and most efficient car.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 my-3">
                    <div className="serv_img text-center">

                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <div className="row py-4">
                  <div className="col-md-6 my-3">
                    <div className="serv_dec">
                      <p>
                        Teams must find and secure partnerships with businesses
                        and individuals to fund their project. This involves
                        creating a business plan, pitching to potential
                        sponsors, and managing relationships with these
                        partners.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 my-3">
                    <div className="serv_img text-center">
 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* who */}
      <section className="who p-5" id="about">
        <div className="row py-5">
          <div className="col-md-4 hourse-box" data-aos="fade-right">
            <img src={hourse} alt="" className="w-75" />
          </div>
          <div className="col-md-3"></div>
          <div
            className="col-md-8 my-2 mx-5 col-who"
            style={{ textAlign: "initial" }}
          >
            <Slider {...settingswho} className="slideritem container py-2">
              <div className="cardbox position-relative">
                <div className="inside  px-3 ">
                  <div className="team-data py-4">
                    <h5 className=" my-5 who-title">
                      <span>
                        <img
                          className="icon"
                          src={titleicon}
                          alt=""
                          style={{ width: "50%" }}
                          data-aos="fade-right"
                        />
                      </span>
                      Who is Abiyya
                    </h5>
                    <p>
                      Abiyya, the mare of the king Abdulaziz, is one of the most
                      famous Arabian horses since the founding of the kingdom of
                      Saudi Arabia
                    </p>
                  </div>
                </div>
              </div>
              {/* 2 */}
              <div className="cardbox position-relative">
                <div className="inside  px-3 text-center">
                  <div className="team-data py-4">
                    <h5 className="mx-5 my-5 who-title">
                      <span>
                        <img
                          className="icon"
                          src={titleicon}
                          alt=""
                          style={{ width: "50%" }}
                          data-aos="fade-right"
                        />
                      </span>
                      Our Vision{" "}
                    </h5>
                    <p>
                      {" "}
                      To reach the world finals 2022/2023, discover our
                      passions,broaden our horizons, and have a positive impact
                      on our society
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
            {/* <div className="data-content">
              <h5 className="mx-5 my-5 who-title">Who is Abiyya</h5>
              <p>Abiyya, the mare of the king Abdulaziz, is one of the most famous Arabian horses since the founding of the kingdom of Saudi Arabia</p>
              </div> */}
          </div>
          {/* <div className="col-md-4">
            </div>
            <div className="col-md-7 py-4 mx-5 data-vision col-who" style={{textAlign: "initial", marginTop: "10%"}}>
              <div className="data-content">
              <h5 className="mx-5 my-5 who-title">Our Vision </h5>
              <p> To reach the world finals 2022/2023, discover our passions,broaden our horizons, and have a positive impact on our society</p>
              </div>
            </div> */}
        </div>
      </section>
      {/* vidoe */}
      <section className="vidoe my-5">
        <div className="container">
          <div className="vedio_box" style={{ height: "77vh" }}>
            <video
              loop="true"
              autoplay="autoplay"
              controls
              muted
              className="w-100 h-100"
            >
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </div>
      </section>
      {/* Team=============================== */}
      <section id="team" className="team py-3 px-4">
        <div className="title py-5 text-center">
          <div className="title-font" style={{ marginTop: "50px" }}>
            <span>
              <img
                src={titleicon}
                alt="ss"
                style={{ width: "50px" }}
                data-aos="fade-right"
              />
            </span>
            Our Team
          </div>
        </div>

        <Slider {...settings} className="slideritem container py-2">
          <div className="cardbox position-relative">
            <div className="inside py-5 px-3 text-center">
              <img src={gana} alt="" className="img-t w-50 position-absolute" />
              <div className="team-data py-4">
                <h3 className="py-3">Jana Alsolamy </h3>
                <label className="my-4">project Manager</label>
                <p className="my-4">
                  Jana is the team leader and project manager for Team Abiyya.
                  She guides the project to success, oversees the timeline,
                  provides feedback, and ensures that milestones are achieved on
                  time. Jana also documents all the team's work.
                </p>
              </div>
            </div>
          </div>
          {/* 2 */}
          <div className="cardbox position-relative">
            <div className="inside py-5 px-3 text-center">
              <img
                src={layan}
                alt=""
                className="img-t w-50 position-absolute"
              />
              <div className="team-data py-4">
                <h3 className="py-3">Layan Jamal</h3>
                <label className="my-4">Team Manager/ Enterprise Manager</label>
                <p className="my-4">
                  Layan is the team and enterprise manager for Team Abi. Her
                  role is to manage the team, oversee the project's progress,
                  allocate resources, and handle budgeting and sponsorships to
                  maximize the project's positive impact on society.
                </p>
              </div>
            </div>
          </div>
          {/* 3 */}
          <div className="cardbox position-relative">
            <div className="inside py-5 px-3 text-center">
              <img
                src={imran}
                alt=""
                className="img-t w-50 position-absolute"
              />
              <div className="team-data py-4">
                <h3 className="py-3">Imran Al-Turkistani</h3>
                <label className="my-4">Marketing and sponsorship</label>
                <p className="my-4">
                  Imran serves as our marketing manager, tasked with managing
                  our social media presence and exploring effective methods to
                  promote our team. He is responsible for ensuring that the team
                  has the best marketing plan in place.
                </p>
              </div>
            </div>
          </div>
          {/* 4 */}
          <div className="cardbox position-relative">
            <div className="inside py-5 px-3 text-center">
              <img src={nada} alt="" className="img-t w-50 position-absolute" />
              <div className="team-data py-4">
                <h3 className="py-3">Nada Al-Qahatani</h3>
                <label className="my-4">
                  Manufacturing Engineer / Graphic Designer
                </label>
                <p className="my-4">
                  Nada is our Manufacturing & design engineer/ graphic designer,
                  she helped in designing the car in CAD and is responsible for
                  transforming our designed car into a manufactured one.
                  Moreover, Nada is also responsible for Branding our team
                </p>
              </div>
            </div>
          </div>
          {/* 5 */}
          <div className="cardbox position-relative">
            <div className="inside py-5 px-3 text-center">
              <img src={saba} alt="" className="img-t w-50 position-absolute" />
              <div className="team-data py-4 d-block">
                <h3 className="py-3">Saba Shehab</h3>
                <label className="my-4">Designing Engineer</label>
                <p className="my-4">
                  Saba contirbutes to the team by being the innovation mind
                  behind our car design, finding the ways to enhance our
                  car's performance.
                </p>
              </div>
            </div>
          </div>
          {/* 6 */}
          <div className="cardbox position-relative">
            <div className="inside py-5 px-3 text-center">
              <img src={ali} alt="" className="img-t w-50 position-absolute" />
              <div className="team-data py-4 d-block">
                <h3 className="py-3">Ali Alnakhli</h3>
                <label className="my-4">Designing Engineer</label>
                <p className="my-4">
                  Ali is our designing engineer he is responsible to come up
                  with creative and efficient ways to design our virtual car
                  with the best aerodynamics to have the best product, while
                  following the competition rules and regulations.
                </p>
              </div>
            </div>
          </div>
        </Slider>
      </section>
      {/* Our sustainability plan */}
      <section id="sustainability" className="sustainability py-3 plan_web_v">
        <div className="title py-5 text-center">
          <div className="title-font" style={{ marginTop: "50px" }}>
            <span>
              <img
                src={titleicon}
                alt="ss"
                style={{ width: "50px" }}
                data-aos="fade-right"
              />
            </span>
            Our sustainability plan
          </div>
        </div>
        <div className="content container ">
          <div className="row">
            {/* row1 */}
            <div className="col-md-6 p-5">
              <p>
                The plan aligns with the2030 vision of Saudi Arabia, which aims
                to promote sustainability and environmental conservation.
              </p>
            </div>
            <div className="col-md-6 p-0">
              <img src={su1} alt="" className="w-100" />
            </div>
            {/* row2 */}
            <div className="col-md-6 p-0">
              <img src={su2} alt="" className="w-100" />
            </div>
            <div className="col-md-6 p-5">
              <p>
                Abiyya seeks to inspire the youth by providing them with a
                platform to learn about sustainable practices and engineering
                principles. We hope to encourage young people to pursue careers
                in STEM fields
              </p>
            </div>
            {/* row3 */}
            <div className="col-md-6 p-5">
              <p>
                we’re focused on the future outcomes of the program. We aim to
                foster innovation and creativity in young people, By promoting
                sustainable practices in we hope to contribute to a better
                future for all.
              </p>
            </div>
            <div className="col-md-6 p-0">
              <img src={su3} alt="" className="w-100" />
            </div>

            {/* row4 */}
            <div className="col-md-6 p-0">
              <img src={su4} alt="" className="w-100" />
            </div>
            <div className="col-md-6 p-5">
              <p>
                We are committed to promoting a green lifestyle . we believe F1
                ins chools program can be a catalyst for change. By promoting
                sustainable practices, we hope to encourage a shift towards a
                greener sustainable way of living. Our sustainability plan
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* mobile_v */}
      <section
        id="sustainability"
        className="sustainability py-3 d-none plan_mob_v"
      >
        <div className="title py-5 text-center">
          <div className="title-font" style={{ marginTop: "50px" }}>
            <span>
              <img
                src={titleicon}
                alt="ss"
                style={{ width: "50px" }}
                data-aos="fade-right"
              />
            </span>
            Our sustainability plan
          </div>
        </div>
        <div className="content container ">
          <div className="row">
            {/* row1 */}
            <div className="col-md-12 my-3">
              <div className="box_all box_1">
                <div className="layer p-5">
                  <p>
                    The plan aligns with the2030 vision of Saudi Arabia, which
                    aims to promote sustainability and environmental
                    conservation.
                  </p>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="col-md-12 my-3">
              <div className="box_all box_2">
                <div className="layer p-5">
                  <p>
                    Abiyya seeks to inspire the youth by providing them with a
                    platform to learn about sustainable practices and
                    engineering principles. We hope to encourage young people to
                    pursue careers in STEM fields
                  </p>
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className="col-md-12 my-3">
              <div className="box_all box_3">
                <div className="layer p-5">
                  <p>
                    we’re focused on the future outcomes of the program. We aim
                    to foster innovation and creativity in young people, By
                    promoting sustainable practices in we hope to contribute to
                    a better future for all.
                  </p>
                </div>
              </div>
            </div>
            {/* 4 */}
            <div className="col-md-12 my-3">
              <div className="box_all box_4">
                <div className="layer p-5">
                  <p>
                    We are committed to promoting a green lifestyle . we believe
                    F1 ins chools program can be a catalyst for change. By
                    promoting sustainable practices, we hope to encourage a
                    shift towards a greener sustainable way of living. Our
                    sustainability plan
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =====================Latest News================== */}
      <section id="last_news" className="last_news py-3">
        <div className="title py-5 text-center">
          <div className="title-font" style={{ marginTop: "50px" }}>
            <span>
              <img
                src={titleicon}
                alt="ss"
                style={{ width: "50px" }}
                data-aos="fade-right"
              />
            </span>
            Latest News
          </div>
        </div>
        <div className="content container">
          <div className="row">
            {/* row1 */}
            <div className="col-md-4 px-5 my-2">
              <div className="NewcardBox">
                <div className="img_box1"></div>
                <div className="data p-3 my-2">
                  <div className="title d-flex justify-content-start align-items-center">
                    <img src={icon1news} alt=".."/>
                    <span className="mx-2">Sabq . </span>
                    <span>4 June,2023</span>
                  </div>
                  <p className="my-2">
                  تمكّنت 3 فرق مشاركة في برنامج "فورمولا 1" في المدارس، والذي ينظمه مركز الملك عبدالعزيز الثقافي العالمي...
                  </p>
                  <a target="blank" href="https://sabq.org/saudia/6bqmq3u5nq#:~:text=4%20%D9%8A%D9%88%D9%86%D9%8A%D9%88%2C%202023%2C%2011%3A58%20%D8%B5%D8%A8%D8%A7%D8%AD%D8%A7%D9%8B%20%D8%AA%D9%85%D9%83%D9%91%D9%86%D8%AA%203%20%D9%81%D8%B1%D9%82,%D9%85%D9%86%2050%20%D9%85%D8%AF%D8%B1%D8%B3%D8%A9%20%D9%82%D8%A7%D8%AF%D9%85%D9%8A%D9%86%20%D9%85%D9%86%20%D8%AE%D9%85%D8%B3%20%D9%85%D8%AF%D9%86%20%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%D8%A9">Continue reading</a>
                </div>
              </div>
            </div>
            <div className="col-md-4 px-5 my-2">
              <div className="NewcardBox">
                <div className="img_box2"></div>
                <div className="data p-3 my-2">
                  <div className="title d-flex justify-content-start align-items-center">
                    <img src={icon2news} alt=".."/>
                    <span className="mx-2">alarabiya . </span>
                    <span>4 June,2023</span>
                  </div>
                  <p className="my-2">
                  3 فرق سعودية من برنامج الفورمولا1 في المدارس تغادر لسنغافورة...
                  </p>
                  <a target="blank" href="https://twitter.com/AlArabiya_KSA/status/1665409580006768640?s=08">Continue reading</a>
                </div>
              </div>
            </div>
            <div className="col-md-4 px-5 my-2">
              <div className="NewcardBox">
                <div className="img_box3"></div>
                <div className="data p-3 my-2">
                  <div className="title d-flex justify-content-start align-items-center">
                    <img src={icon3news} alt=".."/>
                    <span className="mx-2">Twitter . </span>
                    <span>4 June,2023</span>
                  </div>
                  <p className="my-2">
                  من خلال بطولة #أرامكو #فورمولا1_في_المدارس، نسعى لتنمية شغف الإبداع...
                  </p>
                  <a target="blank" href="https://twitter.com/saudi_aramco/status/1665380570581352448?s=46&t=F3a2zEvTzM-dSqkkqzkiQQ">Continue reading</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* sponsers=============================== */}
      <section id="sponsers" className="sponsers py-3 text-center">
        <div className="sliders container">
          <div className="title-v py-5 text-center">
            <div className="title-font">
              <span>
                <img
                  src={titleicon}
                  alt="ss"
                  style={{ width: "50px" }}
                  data-aos="fade-right"
                />
              </span>
              Our Sponsers
            </div>
          </div>
          <Slider
            {...settingsicons}
            className="slideritem container"
            data-aos="fade-down"
          >
            {/* 1 */}
            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={Abce} alt="" style={{ width: "70%" }} />
              </div>
            </div>
            {/* 2 */}
            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={smart} alt="" style={{ width: "70%" }} />
              </div>
            </div>
            {/* 3 */}
            <div className="cardbox">
              <div className="inside  px-3 text-center">
                <img
                  className="mb-2"
                  src={aramco}
                  alt=""
                  style={{ width: "70%" }}
                />
              </div>
            </div>

            {/* 4 */}

            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={Ayato} alt="" style={{ width: "70%" }} />
              </div>
            </div>

            {/* 5 */}

            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={Bell} alt="" style={{ width: "70%" }} />
              </div>
            </div>

            {/* 6 */}

            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={brometra} alt="" style={{ width: "70%" }} />
              </div>
            </div>
            {/* 7 */}

            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={cragne} alt="" style={{ width: "70%" }} />
              </div>
            </div>
            {/* 8 */}

            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={isra} alt="" style={{ width: "70%" }} />
              </div>
            </div>

            {/* 9 */}

            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={nabhal} alt="" style={{ width: "70%" }} />
              </div>
            </div>
            {/* 10 */}

            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={mask} alt="" style={{ width: "70%" }} />
              </div>
            </div>
            {/* 11 */}

            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={rasail} alt="" style={{ width: "70%" }} />
              </div>
            </div>

            {/* 12 */}

            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={Reach} alt="" style={{ width: "70%" }} />
              </div>
            </div>

            {/* 13 */}

            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={sado} alt="" style={{ width: "70%" }} />
              </div>
            </div>
            {/* 14 */}

            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={samnan} alt="" style={{ width: "70%" }} />
              </div>
            </div>
            {/* 15 */}

            <div className="cardbox">
              <div className="inside  px-2 text-center">
                <img src={shopzy} alt="" style={{ width: "70%" }} />
              </div>
            </div>
          </Slider>
        </div>
      </section>
      {/* ============================fqa==================== */}
      {/* <div className="img-line"></div> */}
      <section className="faq py-5" id="faq">
        <div className="title py-5 text-center">
          <div className="title-font">
            <span>
              <img
                src={titleicon}
                alt="ss"
                style={{ width: "50px" }}
                data-aos="fade-right"
              />
            </span>
            FAQ
          </div>
          <div className="line-title my-2"></div>
        </div>
        {/* faq-qu */}
        <div className="container">
          <MDBContainer className="mt-5" style={{ maxWidth: "1000px" }}>
            <h5 className="faq-title my-3">Most asked questions!</h5>

            <MDBAccordion alwaysOpen initialActive={1}>
              <MDBAccordionItem
                collapseId={1}
                headerTitle="What is F1 in Schools?"
              >
                F1 in Schools is a worldwide STEM competition where teams of 3-6
                students compete to create a miniature F1 car, with also
                building an identity for a team.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={2}
                headerTitle="What is the material of the car?"
              >
                The car is primarily a block that is taken from the competition,
                teams can create front, rear wings, and wheels in any material
                they desire
              </MDBAccordionItem>
              <MDBAccordionItem collapseId={3} headerTitle="How can I join?">
                You can start with asking your school if there is a chance to
                join
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={3}
                headerTitle="How long is the competition?"
              >
                Depends on how long you stay, there is an age limit of 11-19,
                but a set of competitions beginning with regionals, nationals,
                and finally the world final would take about 9 months to
                complete
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={3}
                headerTitle="What does the winner of f1 in schools get?"
              >
                The winning team receives the F1 in Schools World Championship
                Trophy as well as scholarships for engineering degrees at UCL,
                the University of Huddersfield and City, University of London,
                teams can also win much more from different aspects
              </MDBAccordionItem>
            </MDBAccordion>
            <textarea
              className="message p-3"
              cols={4}
              rows={4}
              placeholder="Message......"
              onChange={(e) => {
                seMessageValue(e.target.value);
              }}
            />
            <a
              href={`mailto:abiyya.f1inschools@gmail.com?subject=More%20questions&body=${messageValue}`}
            >
              <button className="btn-send my-3">Send</button>
            </a>
          </MDBContainer>
        </div>
      </section>
    </>
  );
};

export default Navbar;
